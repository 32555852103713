import Swiper, {
    Navigation,
    Pagination
} from 'swiper';

// ------------------------------  Swiper ------------------------------

Swiper.use([Navigation, Pagination]);

var swipercont = document.getElementById("swiper-gallery");
if (typeof (swipercont) != 'undefined' && swipercont != null) {
    var swiperlength = document.getElementById("swiper-gallery").getAttribute("data-loopnum");

    var swiper = new Swiper('.swiper-container', {
        loop: true,
        init: false,
        preloadImages: false,
        slidesPerView: 1,
        watchSlidesVisibility: true,
        loopedSlides: swiperlength,
        centeredSlides: true,
        spaceBetween: 64,
        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

    });

    swiper.on('afterInit', function () {
        var currentslide = swiper.realIndex;
        var caption = swiper.slides[currentslide].getAttribute("data-caption");

        // document.getElementById("gallery-caption").innerHTML = caption;

        // document.getElementById("gallery-fullscreen-caption").innerHTML = caption;

        // console.log("Swiper init");
    });

    swiper.init();


    swiper.on('slideChange', function () {
        var currentslide = swiper.realIndex;
        var caption = swiper.slides[currentslide].getAttribute("data-caption");

        // document.getElementById("gallery-fullscreen-caption").innerHTML = caption;
        // document.getElementById("gallery-caption").innerHTML = caption;
    });


} else false;



// Residents swiper
var swiperrescont = document.getElementsByClassName('swiper-res-container');
if (swiperrescont.length > 0) {

    var swiperRes = new Swiper('.swiper-res-container', {
        loop: true,
        init: false,
        preloadImages: false,
        slidesPerView: 1,
        watchSlidesVisibility: true,
        loopedSlides: swiperlength,
        centeredSlides: false,
        spaceBetween: 64,
        // If we need pagination
        pagination: {
            el: '.swiper-pagination-res',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-res-next',
            prevEl: '.swiper-button-res-prev',
        },

    });

    swiperRes.on('afterInit', function () {
        var currentslide = swiperRes.realIndex;
        var caption = swiperRes.slides[currentslide].getAttribute("data-caption");
    });

    swiperRes.init();


    swiperRes.on('slideChange', function () {
        var currentslide = swiperRes.realIndex;
        var caption = swiperRes.slides[currentslide].getAttribute("data-caption");
    });


} else false;

// Studios swiper
var swiperstucont = document.getElementsByClassName('swiper-stu-container');
if (swiperstucont.length > 0) {

    var swiperStu = new Swiper('.swiper-stu-container', {
        loop: true,
        init: false,
        preloadImages: false,
        slidesPerView: 1,
        watchSlidesVisibility: true,
        loopedSlides: swiperlength,
        centeredSlides: false,
        spaceBetween: 64,
        // If we need pagination
        pagination: {
            el: '.swiper-pagination-stu',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-stu-next',
            prevEl: '.swiper-button-stu-prev',
        },

    });

    swiperStu.on('afterInit', function () {
        var currentslide = swiperStu.realIndex;
        var caption = swiperStu.slides[currentslide].getAttribute("data-caption");
    });

    swiperStu.init();


    swiperStu.on('slideChange', function () {
        var currentslide = swiperStu.realIndex;
        var caption = swiperStu.slides[currentslide].getAttribute("data-caption");
    });


} else false;

// Events swiper
var swiperevecont = document.getElementsByClassName('swiper-eve-container');
if (swiperevecont.length > 0) {

    var swiperEve = new Swiper('.swiper-eve-container', {
        loop: true,
        init: false,
        preloadImages: false,
        slidesPerView: 1,
        watchSlidesVisibility: true,
        loopedSlides: swiperlength,
        centeredSlides: false,
        spaceBetween: 64,
        // If we need pagination
        pagination: {
            el: '.swiper-pagination-eve',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-eve-next',
            prevEl: '.swiper-button-eve-prev',
        },

    });

    swiperEve.on('afterInit', function () {
        var currentslide = swiperEve.realIndex;
        var caption = swiperEve.slides[currentslide].getAttribute("data-caption");
    });

    swiperEve.init();

    swiperEve.on('slideChange', function () {
        var currentslide = swiperEve.realIndex;
        var caption = swiperEve.slides[currentslide].getAttribute("data-caption");
    });


} else false;